
import curator from './objects/curator';
import asyncLoadOnScroll from './objects/asyncLoadOnScroll';


// setup animation

	const numberInRow = 6;

	const selectors = {
		items : '.m-image-grid__item'
	}

	const classes = {
		animateItem : 'm-image-grid__animate-item',
		animateItemLoaded : 'm-image-grid__animate-item--load'
	}

	function chunk(arr, len) {
		let chunks = [],
			i = 0,
			n = arr.length;
		
		while (i < n) {
			chunks.push(arr.slice(i, i += len));
		}
		return chunks;
	}

	function rafThrottle(fn) { // takes a function as parameter 
	  var busy = false;
	  return function() { // returning function (a closure)
	    if (busy) return; // busy? go away! 
	    busy = true; // hanging "busy" plate on the door 
	    fn.apply(this, arguments); // calling function
	    // using rAF to remove the "busy" plate, when browser is ready
	    window.requestAnimationFrame(function() {
	      busy = false;
	    });
	  };
	};

	function moveItems(itemsGrouped, percent) {
		itemsGrouped.forEach(function(item, i) {
			let iteration = i;
			const multiplier = i === 0 && -1 || i; // -1, 1, 2
			const translate = percent * multiplier * 20;
			// 1 or -1 // const multiplierAlt1 = ((i + 1) % 2 || -1);
			// 1, 2, 3 // const multiplierAlt2 = iteration++;
			item.forEach(function(subitem) {
				subitem.style.transform = `translateX(${translate}%)`;
			});
		});
	}

	function initScrollListener(elem) {
		if (!elem) return;

		const itemsGrouped = chunk([...elem.querySelectorAll(selectors.items)], numberInRow);
		let vh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

		function tick() {
			const rect = elem.getBoundingClientRect();
			const scroll = Math.max(0, vh - rect.top);
			const scrollEnd = vh + rect.height;
			const percent = Math.min(1, Math.max(0, scroll / scrollEnd));
			if (percent >= 1 || percent <= 0) return;
			moveItems(itemsGrouped, percent);
		}

		tick();
		window.addEventListener('scroll', rafThrottle(tick));
		window.addEventListener('resize', rafThrottle(() => {
			vh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
			tick();
		}));
	}


// add social posts from curator

	function postToElement(post) {
		const html = `
			<div class="m-image-grid__item">
				<img class="${classes.animateItem}" src="${post.image || ''}" alt="${post.text || ''}">
			</div>
		`.trim();
	    const div = document.createElement('div');
	    div.innerHTML = html;

	    const image = div.firstChild.querySelector('img');

	    // image.addEventListener('error', function(event) {
	    // 	this.parentNode.parentNode.removeChild(this.parentNode);
	    // });

	    image.addEventListener('load', function(event) {
	    	window.requestAnimationFrame(() => {
	    		this.classList.add(classes.animateItemLoaded);
	    	});
	    });
	    return div.firstChild;
	}

	function addPostsToElem(el, posts) {

		const maxItems = 18;
		// spread children array to make it a static collection (el.children is a live NodeList)
		// when appending new posts after current children the index needs to remain constant
		const children = [...el.children]; 

		// filter and image posts so we only have images, 
		// and a maximum of 18, including current children
		const imagePosts = posts
			.filter(post => post.has_image && post.image && !post.is_deleted)
			.slice(0, maxItems - children.length);

		// add new posts inbetween current items
		imagePosts.map((post, index) => {
			const imageEl = postToElement(post);
			const child = children[index];
			if (child) {
				// append after child
				child.parentNode.insertBefore(imageEl, child.nextSibling);
			} else {
				el.appendChild(imageEl);
			}
		});

		// animate in children
		children.forEach(child => {
			const image = child.querySelector('img');
			image.complete && window.requestAnimationFrame(() => image.classList.add(classes.animateItemLoaded))
			!image.complete && image.addEventListener('load', () => {
				window.requestAnimationFrame(() => image.classList.add(classes.animateItemLoaded))
			});
		});
	}


// init

	export const initInstance = function(el) {
		const feedID = el.getAttribute('data-feed-id');

		// already 18 items, just init scroller
		if (el.children.length >= 18)  {
			asyncLoadOnScroll(el, () => {
				[].forEach.call(el.querySelectorAll('img'), function(img) {
					img.classList.add(classes.animateItemLoaded);
				});
				initScrollListener(el);
			});				
			return;
		}

		asyncLoadOnScroll(el, () => {
			curator.getPostsAsync(feedID, (response, error) => {
				// add social posts and init animation
				if (!error && response.posts.length) addPostsToElem(el, response.posts);
				initScrollListener(el);
			});
		});	
	}
